'use client'

import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'

import useAppDispatch from '../../../store/hooks/useAppDispatch'
import { setAuthCompanySignUpModalVisible } from '../../auth/store/authSlice'

const CompanySignUpFilterKey = 'companysignup'

export default function CompanySignUpURLFilter() {
  const isTriggeredRef = useRef(false)
  const dispatch = useAppDispatch()
  const { query } = useRouter()

  useEffect(() => {
    const hasCompanySignUpFlag = Object.keys(query).find(
      key => key.toLowerCase() === CompanySignUpFilterKey,
    )

    if (hasCompanySignUpFlag && isTriggeredRef.current === false) {
      isTriggeredRef.current = true
      dispatch(setAuthCompanySignUpModalVisible(true))
    }
  }, [dispatch, query])

  return null
}
