import useTranslation from 'next-translate/useTranslation'
import clsx from 'clsx'
import { PreOrderStatus } from '@ancon/wildcat-types'

import BodyText from '../../../app/components/BodyText'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import { selectedPreOrderStatusSelector } from '../../store/preOrderSelectors'

import styles from './GroupOrderStatusTag.module.scss'

export default function GroupOrderStatusTag() {
  const { t } = useTranslation('preOrder')

  const preOrderStatus = useAppSelector(selectedPreOrderStatusSelector)

  function getStatusText() {
    switch (preOrderStatus) {
      case PreOrderStatus.ApprovalPending:
        return t('groupOrderStatus.approvalPending')
      case PreOrderStatus.Activated:
        return t('groupOrderStatus.activated')
      default:
        return ''
    }
  }

  return (
    <div
      className={clsx(styles.container, {
        [styles.pending]: preOrderStatus === PreOrderStatus.ApprovalPending,
        [styles.active]: preOrderStatus === PreOrderStatus.Activated,
      })}
    >
      <BodyText as="span" color="body-3">
        {getStatusText()}
      </BodyText>
    </div>
  )
}
