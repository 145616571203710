import {
  CheckoutDetails,
  OrderSummary,
  CustomerOrder,
  CustomerOrderDetails,
  OrderFormat,
  OrderStatus,
  CheckoutOrderType,
  PreOrderStatus,
  CustomerCheckoutListItemAttendee,
  CustomerCheckoutListItem,
  CheckoutItem,
} from '@ancon/wildcat-types'
import { EntityState } from '@reduxjs/toolkit'

export type OrderReducerState = {
  activeOrders: EntityState<CheckoutDetails>
  activeOrderFetchPending: boolean

  pastOrders: EntityState<CustomerOrder>
  pastOrdersFetchPending: boolean

  upcomingOrders: EntityState<CustomerCheckoutListItem>
  upcomingOrdersFetchPending: boolean

  orderDetailsFetchPending: boolean
  orderDetails: CustomerOrderDetails | null

  orderReceiptSendPending: boolean
  isReceiptDownloadPending: boolean

  orderSummary: OrderSummary | null
  orderSummaryFetchPending: boolean
}

export type QROrderStatusPageQuery = {
  incrementalId: string
}

export interface OrderSummaryProps {
  id: string
  outlet: {
    id: string
    name: string
    logoId: string | null
  }
  status: OrderStatus
  orderFormat: OrderFormat
  createdAt: string
  serviceTime?: {
    time: string
  }
  totalAmount: {
    amount: number
    currency: string
  }
  items?: CheckoutItem[]
  attendees?: CustomerCheckoutListItemAttendee[]
  ticketNumber: number
  orderType?: CheckoutOrderType
  hostAttendeeId?: string
  preOrderInfo?: {
    name: string
    status: PreOrderStatus
  }
}

export enum OrdersTab {
  Active = 'ongoing',
  Past = 'past',
  Upcoming = 'upcoming',
}

export type MyOrdersPageQuery = {
  tab?: OrdersTab
}
