import useTranslation from 'next-translate/useTranslation'
import clsx from 'clsx'
import { OutletDetails, OutletListItem } from '@ancon/wildcat-types'

import OutletDetailsOpeningHours from '../../../outlet/components/outletDetailsModal/OutletDetailsOpeningHours'
import OutletInformation from '../../../outlet/components/outletDetailsModal/OutletInformation'
import BodyText from '../../../app/components/BodyText'
import OutletCardOutletInfoSummary from '../outletCard/OutletCardOutletInfoSummary'

import RestaurantDetailsSkeleton from './RestaurantDetailsSkeleton'
import RestaurantMap from './RestaurantMap'
import styles from './Restaurant.module.scss'

interface RestaurantProps {
  className?: string
  outletListItem?: OutletListItem | null
  outletDetails?: OutletDetails | null
}

export default function Restaurant({
  className,
  outletDetails,
  outletListItem,
}: RestaurantProps) {
  const { t } = useTranslation('outlets')

  if (!outletDetails || !outletListItem) {
    return <RestaurantDetailsSkeleton />
  }

  const { businessNumber, latitude, longitude } = outletDetails

  return (
    <div className={clsx(styles.restaurantContainer, className)}>
      <div className={styles.restaurantHeader}>
        <OutletCardOutletInfoSummary outlet={outletListItem} />
      </div>
      <RestaurantMap coordinates={{ latitude, longitude }} />
      {businessNumber && (
        <BodyText color="body-1" className={styles.businessNumber}>
          {t('selectedRestaurant.businessNumber', {
            businessNumber,
          })}
        </BodyText>
      )}
      <OutletDetailsOpeningHours outletListItem={outletListItem} />
      <OutletInformation
        outletDetails={outletDetails}
        outletListItem={outletListItem}
      />
    </div>
  )
}
