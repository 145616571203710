import { FormProvider, useForm } from 'react-hook-form'
import { batch } from 'react-redux'

import useAppSelector from '../../../store/hooks/useAppSelector'
import {
  preOrderCalendarSelectedDateSelector,
  preOrderIsNewOrderEditModeSelector,
  preOrderNewOrderDateSelector,
  preOrderNewOrderOrderFormatSelector,
  preOrderNewOrderTimeSelector,
} from '../store/preOrderSelectors'
import useAppDispatch from '../../../store/hooks/useAppDispatch'
import NewOrderHeader from '../components/newOrder/NewOrderHeader'
import FormOrderFormatOptions from '../components/newOrder/FormOrderFormatOptions'
import FormOrderDateAccordion from '../components/newOrder/FormOrderDateAccordion'
import FormOrderTimeAccordion from '../components/newOrder/FormOrderTimeAccordion'
import NewOrderProceedButton from '../components/newOrder/NewOrderProceedButton'
import {
  preOrderGoNextNewOrderStep,
  setNewOrderDetails,
} from '../store/preOrderSlice'
import { NewOrderConfigurationFormState, NewOrderStep } from '../types'
import useAppStore from '../../../store/hooks/useAppStore'

import styles from './NewOrderConfigurationForm.module.scss'

interface NewOrderConfigurationFormProps {
  inModal?: boolean
  isMobile?: boolean
}

export default function NewOrderConfigurationForm({
  inModal = false,
  isMobile = false,
}: NewOrderConfigurationFormProps) {
  const dispatch = useAppDispatch()

  const store = useAppStore()

  const calendarSelectedDate = useAppSelector(
    preOrderCalendarSelectedDateSelector,
  )

  const orderDate = useAppSelector(preOrderNewOrderDateSelector)
  const orderTime = useAppSelector(preOrderNewOrderTimeSelector)
  const orderFormat = useAppSelector(preOrderNewOrderOrderFormatSelector)

  const form = useForm<NewOrderConfigurationFormState>({
    defaultValues: {
      orderDate: orderDate || calendarSelectedDate || '',
      orderTime: orderTime || null,
      orderFormat: orderFormat || null,
    },
    mode: 'onChange',
  })

  const { formState, handleSubmit } = form

  function handleContinue(values: NewOrderConfigurationFormState) {
    const isEditMode = preOrderIsNewOrderEditModeSelector(store.getState())
    const nextStep = isEditMode
      ? NewOrderStep.Summary
      : NewOrderStep.OrderMethodSelection

    batch(() => {
      dispatch(setNewOrderDetails(values))
      dispatch(preOrderGoNextNewOrderStep({ step: nextStep, isMobile }))
    })
  }

  return (
    <div className={styles.formContainer}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...form}>
        <NewOrderHeader
          className={styles.header}
          inModal={inModal}
          isMobile={isMobile}
          showOutlet
        />
        <FormOrderFormatOptions />
        <FormOrderDateAccordion />
        <div className={styles.divider} />
        <FormOrderTimeAccordion />
        <div className={styles.divider} />
        <NewOrderProceedButton
          className={styles.footer}
          disabled={!formState.isValid}
          onClick={handleSubmit(handleContinue)}
        />
      </FormProvider>
    </div>
  )
}
