import { useEffect, useState } from 'react'
import useTranslation from 'next-translate/useTranslation'
import QuestionIcon from '@ancon/wildcat-ui/shared/icons/question-fill.svg'
import { batch } from 'react-redux'

import HeadingText from '../../../app/components/HeadingText'
import BodyText from '../../../app/components/BodyText'
import GroupOrderForm from '../../forms/GroupOrderForm'
import { NewOrderStep, PreOrderMethod } from '../../types'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import { preOrderNewOrderSelectedOrderMethodSelector } from '../../store/preOrderSelectors'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import {
  preOrderGoNextNewOrderStep,
  setNewOrderDetails,
} from '../../store/preOrderSlice'

import NewOrderHeader from './NewOrderHeader'
import NewOrderMethodSelectButton from './NewOrderMethodSelectButton'
import NewOrderProceedButton from './NewOrderProceedButton'
import styles from './NewOrderMethodSelection.module.scss'

interface NewOrderMethodSelectionProps {
  inModal?: boolean
  isMobile?: boolean
}

export default function NewOrderMethodSelection({
  inModal = false,
  isMobile = false,
}: NewOrderMethodSelectionProps) {
  const { t } = useTranslation('preOrder')

  const dispatch = useAppDispatch()

  const initialSelectedMethod = useAppSelector(
    preOrderNewOrderSelectedOrderMethodSelector,
  )

  const [selectedMethod, setSelectedMethod] = useState<PreOrderMethod>(
    initialSelectedMethod,
  )

  const isNormalOrder = selectedMethod === PreOrderMethod.Individual
  const isGroupOrder = selectedMethod === PreOrderMethod.Group

  useEffect(() => {
    setSelectedMethod(initialSelectedMethod)
  }, [initialSelectedMethod])

  function handleContinueNormalOrder() {
    batch(() => {
      dispatch(
        setNewOrderDetails({
          orderMethod: PreOrderMethod.Individual,
          // Reset group order details
          groupOrderName: null,
          groupOrderSize: null,
        }),
      )
      dispatch(
        preOrderGoNextNewOrderStep({ step: NewOrderStep.Summary, isMobile }),
      )
      document.body.scrollTo({ top: 0, behavior: 'smooth' })
    })
  }

  return (
    <div className={styles.container}>
      <NewOrderHeader
        className={styles.header}
        inModal={inModal}
        isMobile={isMobile}
        showOutlet
      />
      <div className={styles.orderMethodSelection}>
        <div className={styles.methodSelectionLabel}>
          <HeadingText as="h3" color="heading-1">
            {t('newOrder.normalOrGroupOrder')}
          </HeadingText>
          <QuestionIcon />
        </div>
        <div className={styles.orderMethodOptions}>
          <NewOrderMethodSelectButton
            orderMethod={PreOrderMethod.Individual}
            selected={isNormalOrder}
            onSelect={setSelectedMethod}
          />
          <NewOrderMethodSelectButton
            orderMethod={PreOrderMethod.Group}
            selected={isGroupOrder}
            onSelect={setSelectedMethod}
          />
        </div>
        {isGroupOrder && (
          <BodyText as="p" color="body-2" fontSize="1.2rem">
            {t('newOrder.orderMethodDescription')}
          </BodyText>
        )}
      </div>
      {isGroupOrder && <GroupOrderForm inModal={inModal} isMobile={isMobile} />}
      {isNormalOrder && (
        <NewOrderProceedButton onClick={handleContinueNormalOrder} />
      )}
    </div>
  )
}
