import useTranslation from 'next-translate/useTranslation'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { OutletListItem } from '@ancon/wildcat-types'
import { useRouter } from 'next/router'
import clsx from 'clsx'

import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import {
  nearbyOutletsListSelector,
  outletListFetchPendingSelector,
  outletsFiltersSelector,
  outletsHasMoreSelector,
  outletsOffsetSelector,
  outletsSelectedRestaurantIdSelector,
  restaurantSelectionModalVisibleSelector,
} from '../../store/outletsSelectors'
import { outletsSetOutletsFilters } from '../../store/outletsSlice'
import { fetchNearbyOutlets } from '../../store/outletsThunks'
import getOutletSectionList from '../../utils/getOutletSectionList'
import OutletCardSkeleton from '../outletCard/OutletCardSkeleton'
import useAppStore from '../../../../store/hooks/useAppStore'
import getOutletListQueryParams from '../../utils/getOutletListQueryParams'
import Button from '../../../app/components/Button'
import HeadingText from '../../../app/components/HeadingText'
import EmptyPlaceholder from '../../../app/components/emptyPlaceholder/EmptyPlaceholder'
import { OutletsPaginationLimit } from '../../constants'
import { OutletsListPageQuery, RestaurantSelectionModalType } from '../../types'

import RestaurantSuggestionCard from './RestaurantSuggestionCard'
import styles from './RestaurantSuggestionsList.module.scss'

interface RestaurantSuggestionsListProps {
  onSelectRestaurant?: (outlet: OutletListItem) => void
}

export default function RestaurantSuggestionsList({
  onSelectRestaurant,
}: RestaurantSuggestionsListProps) {
  const { t } = useTranslation('outlets')

  const dispatch = useAppDispatch()

  const store = useAppStore()

  const router = useRouter()

  const query = router.query as OutletsListPageQuery

  const [isInitialOutletsLoading, setInitialOutletsLoading] =
    useState<boolean>(true)

  const [selectedOutletItemId, setSelectedOutletItemId] = useState<
    string | null
  >(null)

  const modalVisibleType = useAppSelector(
    restaurantSelectionModalVisibleSelector,
  )

  const isOutletListPending = useAppSelector(outletListFetchPendingSelector)

  const outlets = useAppSelector(nearbyOutletsListSelector)

  const hasMore = useAppSelector(outletsHasMoreSelector)

  const selectedRestaurantId = useAppSelector(
    outletsSelectedRestaurantIdSelector,
  )

  const outletSections = getOutletSectionList(outlets, t)

  useEffect(() => {
    if (selectedRestaurantId && selectedRestaurantId !== selectedOutletItemId) {
      setSelectedOutletItemId(selectedRestaurantId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRestaurantId])

  useEffect(() => {
    async function handleFetchInitialOutlets() {
      await dispatch(
        fetchNearbyOutlets({
          offset: 0,
          limit: OutletsPaginationLimit.Initial,
          searchTerm: '',
        }),
      ).unwrap()

      dispatch(outletsSetOutletsFilters({ searchTerm: '' }))
      setInitialOutletsLoading(false)
    }

    if (!query.pl && modalVisibleType !== RestaurantSelectionModalType.None) {
      handleFetchInitialOutlets()
    }
  }, [dispatch, modalVisibleType, query])

  const handleLoadMoreSuggestions = useCallback(() => {
    const offset = outletsOffsetSelector(store.getState())
    const outletsFilters = outletsFiltersSelector(store.getState())
    const params = getOutletListQueryParams(outletsFilters)

    dispatch(
      fetchNearbyOutlets({
        ...params,
        offset,
        limit: OutletsPaginationLimit.LoadMore,
      }),
    )
  }, [dispatch, store])

  function isOutletSelected(outlet: OutletListItem) {
    return !!selectedOutletItemId && selectedOutletItemId === outlet.id
  }

  function handleClickRestaurantSuggestion(outlet: OutletListItem) {
    if (!isOutletSelected(outlet)) {
      setSelectedOutletItemId(outlet.id)
      onSelectRestaurant?.(outlet)
    }
  }

  const skeletonCards = useMemo(() => {
    const maxSkeletons = 2
    const currentSkeletons =
      isOutletListPending && outlets.length < maxSkeletons
        ? maxSkeletons - outlets.length
        : 0

    return Array.from(Array(currentSkeletons).keys())
  }, [isOutletListPending, outlets.length])

  return (
    <div className={styles.listContainer}>
      {outlets.length > 0 && (
        <>
          {outletSections.map(outletSection => (
            <section key={outletSection.title}>
              {outletSection.title && (
                <HeadingText as="h3">{outletSection.title}</HeadingText>
              )}
              <div className={styles.cardsWrapper}>
                {outletSection.data.map(outlet => (
                  <RestaurantSuggestionCard
                    outlet={outlet}
                    key={outlet.id}
                    onClick={handleClickRestaurantSuggestion}
                    selected={isOutletSelected(outlet)}
                  />
                ))}
              </div>
            </section>
          ))}
          {hasMore && !isOutletListPending && (
            <section className={styles.listFooter}>
              <Button
                variant="secondary"
                disabled={isOutletListPending}
                onClick={handleLoadMoreSuggestions}
                outlined
              >
                {t('loadMore')}
              </Button>
            </section>
          )}
        </>
      )}
      {!!skeletonCards.length && (
        <div
          className={clsx(styles.cardsWrapper, {
            [styles.marginTop]: !!outlets.length,
          })}
        >
          {skeletonCards.map(index => (
            <OutletCardSkeleton key={index} className={styles.skeletonCard} />
          ))}
        </div>
      )}
      {!(isInitialOutletsLoading || isOutletListPending) &&
        outlets.length === 0 && (
          <EmptyPlaceholder
            title={t('noRestaurantsNearby')}
            message={t('noRestaurantsNearbyDescription')}
            className={styles.noRestaurantsPlaceholder}
          />
        )}
    </div>
  )
}
