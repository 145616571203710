import { FormProvider, useForm } from 'react-hook-form'
import useTranslation from 'next-translate/useTranslation'
import { batch } from 'react-redux'

import FormInput from '../../app/components/FormInput'
import BodyText from '../../app/components/BodyText'
import HeadingText from '../../app/components/HeadingText'
import FormNumberModifierInput from '../../app/components/FormNumberModifierInput'
import NewOrderProceedButton from '../components/newOrder/NewOrderProceedButton'
import { GroupOrderFormState, NewOrderStep, PreOrderMethod } from '../types'
import useAppSelector from '../../../store/hooks/useAppSelector'
import {
  preOrderIsNewOrderEditModeSelector,
  preOrderNewGroupOrderNameSelector,
  preOrderNewGroupOrderSizeSelector,
  preOrderSelectedRestaurantMaxAttendeeLimit,
} from '../store/preOrderSelectors'
import { MinimumGroupOrderSize } from '../constants'
import useAppDispatch from '../../../store/hooks/useAppDispatch'
import {
  preOrderGoNextNewOrderStep,
  setNewOrderDetails,
} from '../store/preOrderSlice'
import useAppStore from '../../../store/hooks/useAppStore'
import GroupOrderMaxAttendeeLimitNote from '../components/newOrder/GroupOrderMaxAttendeeLimitNote'
import preOrderConstraints from '../constraints/preOrderConstraints'

import styles from './GroupOrderForm.module.scss'

interface GroupOrderFormProps {
  inModal?: boolean
  isMobile?: boolean
}

export default function GroupOrderForm({
  inModal = false,
  isMobile = false,
}: GroupOrderFormProps) {
  const { t } = useTranslation('preOrder')

  const dispatch = useAppDispatch()

  const store = useAppStore()

  const groupOrderName = useAppSelector(preOrderNewGroupOrderNameSelector)
  const groupOrderSize = useAppSelector(preOrderNewGroupOrderSizeSelector)
  const groupOrderMaxLimit = useAppSelector(
    preOrderSelectedRestaurantMaxAttendeeLimit,
  )

  function handleContinue(values: GroupOrderFormState) {
    const isEditMode = preOrderIsNewOrderEditModeSelector(store.getState())
    const nextStep =
      isEditMode && inModal ? NewOrderStep.Summary : NewOrderStep.HostPreview
    batch(() => {
      dispatch(
        setNewOrderDetails({
          ...values,
          orderMethod: PreOrderMethod.Group,
        }),
      )
      dispatch(preOrderGoNextNewOrderStep({ step: nextStep, isMobile }))
      document.body.scrollTo({ top: 0, behavior: 'smooth' })
    })
  }

  const form = useForm<GroupOrderFormState>({
    mode: 'onChange',
    defaultValues: {
      groupOrderName: groupOrderName || '',
      groupOrderSize: groupOrderSize || MinimumGroupOrderSize,
    },
  })

  const { formState, handleSubmit } = form

  return (
    <div className={styles.formContainer}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...form}>
        <FormInput
          labelClassName={styles.groupOrderNameLabel}
          fieldName="groupOrderName"
          label={t('newOrder.letsNameTheGroupOrder')}
          placeholder={t('newOrder.yourGroupOrderNameHere')}
          rules={{
            required: t('common:validations.required'),
            maxLength: {
              value: preOrderConstraints.max.groupOrderName,
              message: t('common:validations.maxLength', {
                limit: preOrderConstraints.max.groupOrderName,
              }),
            },
          }}
        />
        <div className={styles.groupSizeInputWrapper}>
          <HeadingText as="h3" color="heading-1">
            {t('newOrder.howManyInTheGroup')}
          </HeadingText>
          <BodyText color="body-2" fontSize="1.2rem">
            {t('newOrder.enterNumberOfAttendees')}
          </BodyText>
          <FormNumberModifierInput
            containerClassName={styles.groupSizeInput}
            fieldName="groupOrderSize"
            rules={{
              required: t('common:validations.required'),
              min: {
                value: MinimumGroupOrderSize,
                message: t('validations.minGroupSize', {
                  minSize: MinimumGroupOrderSize,
                }),
              },
              max: groupOrderMaxLimit
                ? {
                    value: groupOrderMaxLimit,
                    message: t('validations.maxGroupSize', {
                      maxSize: groupOrderMaxLimit,
                    }),
                  }
                : undefined,
            }}
          />
        </div>
        <GroupOrderMaxAttendeeLimitNote />
        <NewOrderProceedButton
          className={styles.footer}
          disabled={!formState.isValid}
          onClick={handleSubmit(handleContinue)}
        />
      </FormProvider>
    </div>
  )
}
