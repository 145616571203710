import moment from 'moment'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { PreOrderStatus } from '@ancon/wildcat-types'
import noop from 'lodash/noop'

import HeadingText from '../../../app/components/HeadingText'
import BodyText from '../../../app/components/BodyText'
import { NewOrderStep } from '../../types'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import { preOrderGoNextNewOrderStep } from '../../store/preOrderSlice'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import { clientContextCustomerEmailSelector } from '../../../clientContext/store/clientContextSelectors'
import {
  groupPreOrderCreatePendingSelector,
  preOrderNewGroupOrderNameSelector,
  preOrderNewOrderDateSelector,
  preOrderNewOrderIsGroupOrderSelector,
  preOrderNewOrderOrderFormatSelector,
  preOrderNewOrderSelectedOrderMethodSelector,
  preOrderNewOrderTimeSelector,
} from '../../store/preOrderSelectors'
import {
  outletsSelectedOutletListItemNameSelector,
  outletsSelectedOutletListItemUrlSlugSelector,
} from '../../../outlets/store/outletsSelectors'
import useAppStore from '../../../../store/hooks/useAppStore'
import { OrderFormatSlugMap } from '../../../app/constants'
import { createGroupPreOrder } from '../../store/preOrderThunks'
import PreOrderConfigurationOverview, {
  PreOrderConfigField,
} from '../PreOrderConfigurationOverview'

import NewOrderHeader from './NewOrderHeader'
import NewOrderProceedButton from './NewOrderProceedButton'
import styles from './NewOrderSummary.module.scss'

interface NewOrderSummaryProps {
  onEdit?: (step: NewOrderStep) => void
  isMobile?: boolean
}

export default function NewOrderSummary({
  onEdit,
  isMobile = false,
}: NewOrderSummaryProps) {
  const { t } = useTranslation('preOrder')

  const router = useRouter()

  const store = useAppStore()

  const dispatch = useAppDispatch()

  const outletName = useAppSelector(outletsSelectedOutletListItemNameSelector)
  const orderDate = useAppSelector(preOrderNewOrderDateSelector)
  const orderTimestamp = useAppSelector(preOrderNewOrderTimeSelector)
  const orderFormat = useAppSelector(preOrderNewOrderOrderFormatSelector)
  const isGroupOrder = useAppSelector(preOrderNewOrderIsGroupOrderSelector)
  const orderMethod = useAppSelector(
    preOrderNewOrderSelectedOrderMethodSelector,
  )
  const groupOrderName = useAppSelector(preOrderNewGroupOrderNameSelector)
  const customerEmail = useAppSelector(clientContextCustomerEmailSelector)
  const isOrderSubmitting = useAppSelector(groupPreOrderCreatePendingSelector)

  async function handleContinue() {
    const urlSlug = outletsSelectedOutletListItemUrlSlugSelector(
      store.getState(),
    )

    if (isGroupOrder) {
      try {
        const groupOrder = await dispatch(createGroupPreOrder()).unwrap()
        const { preOrderStatus } = groupOrder

        switch (preOrderStatus) {
          case PreOrderStatus.ApprovalPending:
            dispatch(
              preOrderGoNextNewOrderStep(NewOrderStep.GroupOrderReceived),
            )
            break
          // For auto accept setting ON case
          case PreOrderStatus.Activated:
            dispatch(
              preOrderGoNextNewOrderStep(NewOrderStep.ShareGroupOrderLink),
            )
            break
          default:
            break
        }
      } catch (error) {
        noop()
      }
    } else {
      // Navigate to outlet page
      router.push({
        pathname: `outlet/${urlSlug}`,
        query: {
          orderFormat: OrderFormatSlugMap[orderFormat as never],
          serviceTime: moment(orderTimestamp).toISOString(),
        },
      })
    }
  }

  function handleEditNewOrder(dataType: PreOrderConfigField) {
    switch (dataType) {
      case PreOrderConfigField.OrderDate:
      case PreOrderConfigField.OrderTime:
      case PreOrderConfigField.OrderType:
        onEdit?.(NewOrderStep.OrderConfiguration)
        break
      case PreOrderConfigField.OrderMethod:
        onEdit?.(NewOrderStep.OrderMethodSelection)
        break
      default:
        break
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.summary}>
          <NewOrderHeader title={t('newOrder.summary')} isMobile={isMobile} />
          {isGroupOrder && (
            <div className={styles.summaryNote}>
              <BodyText as="label">{t('newOrder.summaryNote')}</BodyText>
            </div>
          )}
          <HeadingText as="h2" className={styles.summaryHeader}>
            {groupOrderName ?? t('newOrder.placeANewOrder')}
          </HeadingText>
          <PreOrderConfigurationOverview
            dataMapper={{
              [PreOrderConfigField.Restaurant]: outletName,
              [PreOrderConfigField.OrderDate]: orderDate,
              [PreOrderConfigField.OrderTime]: orderTimestamp,
              [PreOrderConfigField.OrderType]: orderFormat,
              [PreOrderConfigField.OrderMethod]: orderMethod,
              [PreOrderConfigField.Customer]: customerEmail,
            }}
            editable
            handleEdit={handleEditNewOrder}
          />
        </div>
      </div>
      <NewOrderProceedButton
        className={styles.footer}
        buttonText={t(
          isGroupOrder
            ? 'newOrder.placeTheOrderRequest'
            : 'newOrder.goToOutletPage',
        )}
        buttonNote={t('newOrder.ifAllGoodLetsProceed')}
        onClick={handleContinue}
        loading={isOrderSubmitting}
      />
    </div>
  )
}
