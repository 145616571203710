import BodyText from '@ancon/wildcat-ui/web/orderweb/BodyText'
import useTranslation from 'next-translate/useTranslation'
import { OrderFormat, OutletDetails, OutletState } from '@ancon/wildcat-types'
import getOutletFormattedAddress from '@ancon/wildcat-utils/outlet/getOutletFormattedAddress'
import getOutletOrderFormats from '@ancon/wildcat-utils/outlet/getOutletOrderFormats'
import Button from '@ancon/wildcat-ui/web/orderweb/Button'
import OrderTypeIcon from '@ancon/wildcat-ui/web/orderweb/OrderTypeIcon'
import clsx from 'clsx'
import HeadingText from '@ancon/wildcat-ui/web/orderweb/HeadingText'
import getFormattedPhoneNumber from '@ancon/wildcat-utils/user/getFormattedPhoneNumber'
import PhoneIcon from '@ancon/wildcat-ui/shared/icons/phone-volume-outline.svg'
import EmailIcon from '@ancon/wildcat-ui/shared/icons/email-outline.svg'
import LinkIcon from '@ancon/wildcat-ui/shared/icons/arrow-up-right-outline.svg'
import Link from 'next/link'
import isOutletAcceptingPreOrders from '@ancon/wildcat-utils/outlet/isOutletAcceptingPreOrders'

import useAppSelector from '../../../../store/hooks/useAppSelector'
import { outletsSelectedOutletListItemSelector } from '../../store/outletsSelectors'
import { orderFormatTextMap } from '../../constants'
import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import { outletSetIsVisibleOutletDetailsModal } from '../../../outlet/store/outletSlice'
import OutletCardOutletStatus from '../outletCard/OutletCardOutletStatus'

import styles from './NewOrderOutletDetails.module.scss'

type NewOrderOutletDetailsProps = {
  isInModal?: boolean
  outletDetails: OutletDetails
  onClickOrderFormat: (orderFormat: OrderFormat) => void
}

export default function NewOrderOutletDetails({
  isInModal,
  outletDetails,
  onClickOrderFormat,
}: NewOrderOutletDetailsProps) {
  const { t } = useTranslation('outlets')
  const dispatch = useAppDispatch()

  const outletListItem = useAppSelector(outletsSelectedOutletListItemSelector)!
  const isOutletOpen = outletListItem.state === OutletState.Open
  const isAcceptingPreOrders = isOutletAcceptingPreOrders(
    outletListItem.orderFormatSettings,
  )
  const isOrderingEnable = isOutletOpen || isAcceptingPreOrders

  const { name, street, city, telephone, email } = outletDetails

  const orderFormats = getOutletOrderFormats(outletListItem)
  const address = getOutletFormattedAddress(street, city)

  function formatPhoneNumber() {
    try {
      return getFormattedPhoneNumber(telephone)
    } catch (err) {
      return telephone
    }
  }

  function handleOnClickInfo() {
    dispatch(outletSetIsVisibleOutletDetailsModal(true))
  }

  const contactInfoRows = [
    {
      href: `mailto:${email}`,
      text: email,
      icon: <EmailIcon />,
    },
  ]

  if (telephone) {
    contactInfoRows.unshift({
      href: `tel:${telephone}`,
      text: formatPhoneNumber(),
      icon: <PhoneIcon />,
    })
  }

  return (
    <div>
      <div className={styles.header}>
        <div
          className={clsx(styles.left, {
            [styles.grow]: !isInModal,
          })}
        >
          <HeadingText as="h2" className={styles.outletName}>
            {name}
          </HeadingText>
        </div>
        <div className={styles.right}>
          <Button
            variant="secondary"
            className={styles.infoButton}
            onClick={handleOnClickInfo}
          >
            {t('newOrderCard.info')}
          </Button>
        </div>
      </div>
      <BodyText color="body-1">{address}</BodyText>
      {outletListItem.state === OutletState.Closed && (
        <OutletCardOutletStatus
          outlet={outletListItem}
          containerClassName={styles.statusWrapper}
        />
      )}
      <div className={clsx(styles.row, styles.orderFormats)}>
        {orderFormats.map(orderFormat => (
          <Button
            key={orderFormat}
            onClick={() => onClickOrderFormat(orderFormat)}
            disabled={!isOrderingEnable}
          >
            <OrderTypeIcon orderFormat={orderFormat} />
            <BodyText color="body-3" fontSize="1.6rem">
              {t(`common:${orderFormatTextMap[orderFormat as never]}`)}
            </BodyText>
          </Button>
        ))}
      </div>
      <div className={styles.contactInfoRows}>
        {contactInfoRows.map(info => (
          <Link key={info.href} href={info.href}>
            <span className={styles.contactInfoRow}>
              {info.icon}
              <BodyText fontSize="1.6rem">{info.text}</BodyText>
              <LinkIcon />
            </span>
          </Link>
        ))}
      </div>
    </div>
  )
}
