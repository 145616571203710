import { OrderFormat, OutletListItem } from '@ancon/wildcat-types'
import getOutletOrderFormats from '@ancon/wildcat-utils/outlet/getOutletOrderFormats'

export default function getPreOrderOrderFormats(outlet: OutletListItem) {
  const outletOrderFormats = getOutletOrderFormats(outlet)

  return outletOrderFormats.filter(
    // `TableOrder & BoxPickup` is not supported for pre-order
    orderFormat =>
      orderFormat !== OrderFormat.TableOrder &&
      orderFormat !== OrderFormat.BoxPickup,
  )
}
