import { useMemo } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import useTranslation from 'next-translate/useTranslation'
import ClockIcon from '@ancon/wildcat-ui/shared/icons/clock-circle-fill.svg'
import getOutletPickerTimesForDay from '@ancon/wildcat-utils/outlet/getOutletPickerTimesForDay'
import clsx from 'clsx'

import Accordion from '../../../app/components/Accordion'
import BodyText from '../../../app/components/BodyText'
import Selectable, { SelectableType } from '../../../app/components/Selectable'
import useToggleState from '../../../app/hooks/useToggleState'
import { NewOrderConfigurationFormState } from '../../types'
import { outletsSelectedOutletListItemSelector } from '../../../outlets/store/outletsSelectors'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import getFormattedOrderTimeSlot from '../../utils/getFormattedOrderTimeSlot'
import PreOrderIconDataField from '../PreOrderIconDataField'

import styles from './FormOrderTimeAccordion.module.scss'

export default function FormOrderTimeAccordion() {
  const { t, lang } = useTranslation('preOrder')

  const [isExpanded, toggle] = useToggleState(false)

  const outletListItem = useAppSelector(outletsSelectedOutletListItemSelector)!

  const { watch } = useFormContext<NewOrderConfigurationFormState>()

  const selectedDate = watch('orderDate')
  const selectedOrderFormat = watch('orderFormat')

  const {
    field: { value, onChange },
  } = useController<NewOrderConfigurationFormState>({
    name: 'orderTime',
    rules: { required: true },
  })

  const pickerTimes = useMemo(() => {
    if (selectedOrderFormat && selectedDate) {
      return getOutletPickerTimesForDay(
        outletListItem,
        selectedOrderFormat,
        selectedDate,
        lang,
      )
    }

    return []
  }, [selectedOrderFormat, selectedDate, outletListItem, lang])

  const selectedTimeOptionIndex = useMemo(
    () =>
      pickerTimes.findIndex(
        pickerTime => pickerTime.timestamp === (value as number),
      ),
    [pickerTimes, value],
  )

  function getEmptyTimeOptionText() {
    if (selectedDate && selectedOrderFormat) {
      return t('newOrder.noAvailableTimeOptions')
    }

    return t('newOrder.selectDateAndOrderFormat')
  }

  function handleOnChangeTime(timeIndex: number) {
    onChange(pickerTimes[timeIndex].timestamp)
  }

  return (
    <Accordion
      containerClassName={styles.accordion}
      headerContainerClassName={styles.headerContainer}
      contentContainerClassName={styles.accordionContent}
      caretClassName={styles.caret}
      onToggle={toggle}
      expanded={isExpanded}
      title={
        <PreOrderIconDataField
          icon={<ClockIcon />}
          label={t('newOrder.orderAtAround')}
          placeholder={t('newOrder.setTime')}
        >
          {getFormattedOrderTimeSlot(value as number)}
        </PreOrderIconDataField>
      }
    >
      {pickerTimes.length ? (
        <div className={styles.timeOptionsContainer}>
          {pickerTimes.map((pickerTime, index) => (
            <div
              className={styles.timeOption}
              key={pickerTime.timestamp}
              onClick={() => handleOnChangeTime(index)}
              role="presentation"
            >
              <BodyText as="span">
                {getFormattedOrderTimeSlot(pickerTime.timestamp)}
              </BodyText>
              <Selectable
                type={SelectableType.RadioButton}
                containerClassName={clsx(styles.timeOptionSelectable, {
                  [styles.selected]: index === selectedTimeOptionIndex,
                })}
                checked={index === selectedTimeOptionIndex}
                onClick={() => handleOnChangeTime(index)}
              />
            </div>
          ))}
        </div>
      ) : (
        <BodyText className={styles.noTimeOptions} color="body-2">
          {getEmptyTimeOptionText()}
        </BodyText>
      )}
    </Accordion>
  )
}
