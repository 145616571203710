import React, { PropsWithChildren } from 'react'

import BodyText from '../../app/components/BodyText'

import styles from './PreOrderIconDataField.module.scss'

type PreOrderIconDataFieldProps = PropsWithChildren<{
  icon: React.ReactNode
  label: string
  placeholder?: string
}>

export default function PreOrderIconDataField({
  icon,
  label,
  children: value,
  placeholder,
}: PreOrderIconDataFieldProps) {
  return (
    <div className={styles.fieldContainer}>
      <div className={styles.fieldIcon}>{icon}</div>
      <div className={styles.fieldData}>
        {value && (
          <BodyText as="label" color="body-2" fontSize="1.2rem">
            {label}
          </BodyText>
        )}
        <BodyText as="span">{value || placeholder || ''}</BodyText>
      </div>
    </div>
  )
}
