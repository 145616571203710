import { useEffect, useRef, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { batch } from 'react-redux'
import useTranslation from 'next-translate/useTranslation'
import SearchIcon from '@ancon/wildcat-ui/shared/icons/i-search.svg'
import BackIcon from '@ancon/wildcat-ui/shared/icons/arrow-back-long.svg'
import clsx from 'clsx'
import { OutletDetails, OutletListItem } from '@ancon/wildcat-types'
import noop from 'lodash/noop'
import moment from 'moment'

import useAppDispatch from '../../../../store/hooks/useAppDispatch'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import Modal from '../../../app/components/modal/Modal'
import {
  outletListFetchPendingSelector,
  outletsSelectedOutletListItemSelector,
  outletsSelectedRestaurantIdSelector,
  restaurantSelectionModalVisibleSelector,
} from '../../store/outletsSelectors'
import {
  outletsSetOutletsFilters,
  outletsSetSelectedOutletListItem,
  outletsSetSelectedRestaurantId,
  setRestaurantSelectionModalVisible,
} from '../../store/outletsSlice'
import Input from '../../../app/components/Input'
import Spinner from '../../../app/components/Spinner'
import Button from '../../../app/components/Button'
import { fetchNearbyOutlets } from '../../store/outletsThunks'
import { OutletsPaginationLimit } from '../../constants'
import useToggleState from '../../../app/hooks/useToggleState'
import {
  outletDetailsSelector,
  outletIsOutletDetailsPendingSelector,
} from '../../../outlet/store/outletSelector'
import localStorageUtils from '../../../app/utils/localStorageUtils'
import { outletSetOutletDetails } from '../../../outlet/store/outletSlice'
import { fetchOutletDetails } from '../../../outlet/store/outletThunks'
import useAppStore from '../../../../store/hooks/useAppStore'
import { RestaurantSelectionModalType } from '../../types'
import { fetchPreOrderSettings } from '../../../preOrder/store/preOrderThunks'
import {
  preOrderGoNextNewOrderStep,
  setNewOrderDetails,
} from '../../../preOrder/store/preOrderSlice'
import { NewOrderStep } from '../../../preOrder/types'
import useDeviceSize from '../../../app/hooks/useDeviceSize'
import { preOrderCalendarSelectedDateSelector } from '../../../preOrder/store/preOrderSelectors'

import styles from './RestaurantSelectionModal.module.scss'
import RestaurantSuggestionsList from './RestaurantSuggestionsList'
import SelectYourRestaurantPlaceholder from './SelectYourRestaurantPlaceholder'
import Restaurant from './Restaurant'

export default function RestaurantSelectionModal() {
  const { t } = useTranslation('outlets')

  const dispatch = useAppDispatch()

  const store = useAppStore()

  const searchInputRef = useRef<HTMLInputElement>(null)

  const [isRestaurantShown, toggleRestaurantDetails, setRestaurantShown] =
    useToggleState(false)

  const [selectedOutletListItem, setSelectedOutletListItem] =
    useState<OutletListItem | null>(null)

  const [selectedOutletDetails, setSelectedOutletDetails] =
    useState<OutletDetails | null>(null)

  const modalVisibleType = useAppSelector(
    restaurantSelectionModalVisibleSelector,
  )

  const outletListFetchPending = useAppSelector(outletListFetchPendingSelector)

  const isOutletDetailsPending = useAppSelector(
    outletIsOutletDetailsPendingSelector,
  )

  const selectedRestaurantId = useAppSelector(
    outletsSelectedRestaurantIdSelector,
  )

  const { isMobile } = useDeviceSize()

  const selectedOutletListItemId = selectedOutletListItem?.id

  const isSelectRestaurantDisabled =
    !selectedOutletListItemId ||
    selectedOutletListItemId === selectedRestaurantId ||
    isOutletDetailsPending

  useEffect(() => {
    function initSelectedOutlet() {
      const outletDetails = outletDetailsSelector(store.getState())
      const outletListItem = outletsSelectedOutletListItemSelector(
        store.getState(),
      )

      if (selectedRestaurantId && outletListItem && outletDetails) {
        setSelectedOutletListItem(outletListItem)
        setSelectedOutletDetails(outletDetails)
      }
    }

    if (modalVisibleType) {
      initSelectedOutlet()
    }
  }, [modalVisibleType, selectedRestaurantId, store])

  function handleModalDismiss() {
    dispatch(
      setRestaurantSelectionModalVisible(RestaurantSelectionModalType.None),
    )
    setRestaurantShown(false)
    setSelectedOutletListItem(null)
    setSelectedOutletDetails(null)
  }

  function searchOutlets(searchTerm: string) {
    batch(() => {
      dispatch(
        fetchNearbyOutlets({
          offset: 0,
          limit: OutletsPaginationLimit.Initial,
          searchTerm,
          keepExisting: true,
        }),
      )
      dispatch(outletsSetOutletsFilters({ searchTerm }))
    })
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const searchTerm = event.target.value

    searchOutlets(searchTerm)
  }

  const debouncedHandleChange = useDebouncedCallback(handleChange, 500)

  function handleClearSearch() {
    searchOutlets('')
    if (searchInputRef.current) {
      searchInputRef.current.value = ''
    }
  }

  async function handleSelectRestaurantSuggestion(outlet: OutletListItem) {
    try {
      setSelectedOutletListItem(outlet)
      setSelectedOutletDetails(null)
      const outletDetails = await dispatch(
        fetchOutletDetails({
          outletId: outlet.id,
          skipStoreUpdate: true,
        }),
      ).unwrap()
      setSelectedOutletDetails(outletDetails)
    } catch (error) {
      noop()
    }
  }

  async function createNewOrder() {
    try {
      await dispatch(fetchPreOrderSettings()).unwrap()
    } catch (error) {
      noop()
    } finally {
      dispatch(
        preOrderGoNextNewOrderStep({
          step: NewOrderStep.OrderConfiguration,
          isMobile,
        }),
      )
      const orderPlaceDate =
        preOrderCalendarSelectedDateSelector(store.getState()) ||
        moment().format('YYYY-MM-DD')
      dispatch(setNewOrderDetails({ orderDate: orderPlaceDate }))
    }
  }

  function handleSaveSelectedRestaurant() {
    if (selectedOutletListItemId) {
      localStorageUtils.setItem<string>(
        'preSelectedOutletId',
        selectedOutletListItemId,
      )
      batch(() => {
        dispatch(outletSetOutletDetails(selectedOutletDetails))
        dispatch(outletsSetSelectedOutletListItem(selectedOutletListItem))
        dispatch(outletsSetSelectedRestaurantId(selectedOutletListItemId))
        dispatch(
          setRestaurantSelectionModalVisible(RestaurantSelectionModalType.None),
        )
      })

      if (modalVisibleType === RestaurantSelectionModalType.PlaceNewOrder) {
        createNewOrder()
      }
    }
  }

  return (
    <Modal
      title={t('selectedRestaurant.selectRestaurant')}
      isOpen={modalVisibleType !== RestaurantSelectionModalType.None}
      onClose={handleModalDismiss}
      mobileModalMode="bottom-sheet"
      contentClassName={styles.modalContent}
      modalHeaderClassName={styles.modalHeader}
    >
      <div className={styles.modalBody}>
        <div
          className={clsx(styles.leftContent, {
            [styles.hiddenInMobile]: isRestaurantShown,
          })}
        >
          <Input
            leftAccessory={<SearchIcon />}
            rightAccessory={
              outletListFetchPending ? <Spinner size="small" /> : undefined
            }
            containerClassName={styles.searchInputContainer}
            placeholder={t('searchRestaurant')}
            onChange={debouncedHandleChange}
            clearable={!!searchInputRef.current?.value}
            rightAccessoryOnClick={handleClearSearch}
            ref={searchInputRef}
          />
          <RestaurantSuggestionsList
            onSelectRestaurant={handleSelectRestaurantSuggestion}
          />
        </div>
        <div
          className={clsx(styles.rightContent, {
            [styles.hiddenInMobile]: !isRestaurantShown,
          })}
        >
          {selectedOutletListItemId ? (
            <Restaurant
              className={styles.restaurant}
              outletListItem={selectedOutletListItem}
              outletDetails={selectedOutletDetails}
            />
          ) : (
            <SelectYourRestaurantPlaceholder />
          )}
          <div className={styles.rightFooter}>
            <Button
              size="large"
              disabled={isSelectRestaurantDisabled}
              onClick={handleSaveSelectedRestaurant}
              outlined
            >
              {t('selectedRestaurant.selectRestaurant')}
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.modalFooterMobile}>
        <Button
          className={styles.secondary}
          variant="secondary"
          disabled={!selectedOutletListItemId}
          onClick={toggleRestaurantDetails}
          outlined
        >
          {isRestaurantShown && <BackIcon />}
          {isRestaurantShown
            ? t('selectedRestaurant.back')
            : t('selectedRestaurant.viewRestaurant')}
        </Button>
        <Button
          disabled={isSelectRestaurantDisabled}
          onClick={handleSaveSelectedRestaurant}
          outlined
        >
          {t('selectedRestaurant.selectRestaurant')}
        </Button>
      </div>
    </Modal>
  )
}
