import useTranslation from 'next-translate/useTranslation'
import { useController } from 'react-hook-form'
import { OrderFormat } from '@ancon/wildcat-types'

import OutletOrderFormats from '../../../outlets/components/OutletOrderFormats'
import useAppSelector from '../../../../store/hooks/useAppSelector'
import { outletsSelectedOutletListItemSelector } from '../../../outlets/store/outletsSelectors'
import getPreOrderOrderFormats from '../../utils/getPreOrderOrderFormats'
import BodyText from '../../../app/components/BodyText'
import { NewOrderConfigurationFormState } from '../../types'

import styles from './FormOrderFormatOptions.module.scss'

export default function FormOrderFormatOptions() {
  const { t } = useTranslation('preOrder')

  const outletListItem = useAppSelector(outletsSelectedOutletListItemSelector)!

  const supportedOrderFormats = getPreOrderOrderFormats(outletListItem)

  const {
    field: { value, onChange },
  } = useController<NewOrderConfigurationFormState>({
    name: 'orderFormat',
    rules: { required: true },
  })

  return (
    <div className={styles.container}>
      <BodyText color="body-2" fontSize="1.2rem">
        {t('newOrder.orderAs')}
      </BodyText>
      <OutletOrderFormats
        className={styles.orderFormats}
        selectedOrderFormatClassName={styles.selectedOrderFormat}
        selectedOrderFormat={value as OrderFormat}
        orderFormats={supportedOrderFormats}
        onChange={onChange}
      />
    </div>
  )
}
