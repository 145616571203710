import Skeleton from '../../../app/components/Skeleton'

import styles from './RestaurantDetailsSkeleton.module.scss'

export default function RestaurantDetailsSkeleton() {
  return (
    <div className={styles.column}>
      <Skeleton className={styles.restaurantName} />
      <div className={styles.restaurantAddress}>
        <Skeleton className={styles.addressLine1} />
        <Skeleton className={styles.addressLine2} />
        <Skeleton className={styles.distance} />
      </div>
      <Skeleton className={styles.restaurantMap} />
      <Skeleton className={styles.businessNumber} />
      <div className={styles.restaurantMetaDataList}>
        {Array.from(Array(3).keys()).map(index => (
          <div key={index} className={styles.dataRow}>
            <Skeleton className={styles.dataLogo} />
            <div className={styles.dataLines}>
              <Skeleton className={styles.dataLine1} />
              <Skeleton className={styles.dataLine2} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
