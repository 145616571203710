import CalendarIcon from '@ancon/wildcat-ui/shared/icons/calendar-event-fill.svg'
import { useController } from 'react-hook-form'
import moment from 'moment'
import useTranslation from 'next-translate/useTranslation'

import Accordion from '../../../app/components/Accordion'
import useToggleState from '../../../app/hooks/useToggleState'
import Calendar from '../../../calendar/components/Calendar'
import { NewOrderConfigurationFormState } from '../../types'
import PreOrderIconDataField from '../PreOrderIconDataField'

import styles from './FormOrderDateAccordion.module.scss'

export default function FormOrderDateAccordion() {
  const { t } = useTranslation('preOrder')

  const today = moment()

  const [isExpanded, toggle] = useToggleState(false)

  const {
    field: { value, onChange },
  } = useController<NewOrderConfigurationFormState>({
    name: 'orderDate',
    rules: { required: true },
  })

  function handleSelectDate(date: string) {
    onChange(moment(date).format('YYYY-MM-DD'))
  }

  return (
    <Accordion
      headerContainerClassName={styles.headerContainer}
      caretClassName={styles.caret}
      onToggle={toggle}
      expanded={isExpanded}
      title={
        <PreOrderIconDataField
          icon={<CalendarIcon />}
          label={t('newOrder.orderBy')}
          placeholder={t('newOrder.setDate')}
        >
          {moment(value).format('ddd, ll')}
        </PreOrderIconDataField>
      }
    >
      <Calendar
        className={styles.calendar}
        minDate={today}
        initialSelectedDate={value as string}
        onSelectDate={handleSelectDate}
      />
    </Accordion>
  )
}
