import GroupIcon from '@ancon/wildcat-ui/shared/icons/group-fill.svg'
import EatInIcon from '@ancon/wildcat-ui/shared/icons/eat-in.svg'
import clsx from 'clsx'
import useTranslation from 'next-translate/useTranslation'

import HeadingText from '../../../app/components/HeadingText'
import Button from '../../../app/components/Button'
import { PreOrderMethod } from '../../types'

import styles from './NewOrderMethodSelectButton.module.scss'

interface NewOrderMethodSelectButtonProps {
  orderMethod: PreOrderMethod
  selected?: boolean
  onSelect?: (method: PreOrderMethod) => void
}

function getOrderMethodIcon(orderMethod: PreOrderMethod) {
  switch (orderMethod) {
    case PreOrderMethod.Group:
      return GroupIcon
    case PreOrderMethod.Individual:
    default:
      return EatInIcon
  }
}

export default function NewOrderMethodSelectButton({
  orderMethod,
  selected = false,
  onSelect,
}: NewOrderMethodSelectButtonProps) {
  const { t } = useTranslation('preOrder')

  const OrderMethodIcon = getOrderMethodIcon(orderMethod)

  function getOrderMethodName() {
    switch (orderMethod) {
      case PreOrderMethod.Group:
        return t('newOrder.groupOrder')
      case PreOrderMethod.Individual:
        return t('newOrder.normalOrder')
      default:
        return ''
    }
  }

  function handleSelectMethod() {
    onSelect?.(orderMethod)
  }

  return (
    <Button
      className={clsx(styles.selectButton, {
        [styles.selected]: selected,
      })}
      variant="secondary"
      onClick={handleSelectMethod}
    >
      <span>
        <OrderMethodIcon />
        <HeadingText as="p" size="h3" color="heading-1">
          {getOrderMethodName()}
        </HeadingText>
      </span>
    </Button>
  )
}
